.chatbot {
  width: 300px;
  height: 400px;
  border: 1px solid #ccc;
  overflow-y: scroll;
  padding: 10px;
}

.chat-history {
  height: 300px;
  overflow-y: scroll;
}

.chat-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}


.chat-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}


.chat-input button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.message {
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.message.user {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.message.bot {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}
