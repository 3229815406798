body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background: url('./assets/img/background.jpg') no-repeat center center fixed;
  background-size: cover;
}


.chatbot {
  width: 100%;
  max-width: 450px;
  height: 600px;
  border: 1px solid #ccc;
  overflow-y: auto;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}


.chat-history {
  height: calc(100% - 150px); 
  margin-top: 50px;
  overflow-y: auto;
  padding: 10px 14px;
}

.chat-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  outline: none;
  box-sizing: border-box;
}

.chat-input button {
  padding: 12px 20px;
  height: 42px;
  font-size: 14px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: flex-start;
}

.message-avatar {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.message-text {
  padding: 8px;
  border-radius: 4px;
  max-width: 80%;
  word-wrap: break-word;
}

.message.user {
  flex-direction: row-reverse;
  align-self: flex-end;
}

.logo-container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1100;
  max-width: 80%;
}

.logo-container img {
  width: 30%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.message.bot {
  flex-direction: row;
  align-self: flex-start;
}

.message.bot.typing {
  display: flex;
  align-items: center;
  height: 60px;
}

.message.bot.typing .dot-animation {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 3px;
  animation: typing 1s infinite;
}

@keyframes typing {
  0% {
      opacity: 0.5;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0.5;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.message-text span {
  opacity: 0;
  display: inline-block;
  animation: fadeIn 0.3s forwards;
}

.message-text.left-to-right span {
  animation-delay: calc(var(--animation-order) * 0.04s);
}

.message-text.right-to-left span {
  animation-delay: calc(var(--animation-order) * 0.04s);
}

@media (max-width: 1568px) {
  .logo-container {
      top: 0px; 
      width: 53%;
  }
  .chat-history {
      margin-top: 70px;
      height: 70%;
  }
}

@media (max-width: 768px) {
  .logo-container {
      top: 0px; 
      width: 80%;
  }
  .chat-history {
      margin-top: 70px;
      height: 50%;
  }
  .chatbot {
      width: 100%;
      height: 100%;
      max-width: 100%;
  }
}
